html {
  scroll-behavior: smooth;
}

.pulse {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 15px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  padding: 20px;
  text-align: center;
  width: 100%;
  max-width: 350px;
  border-radius: 10px;
  background: $primary;
  border: 2px solid $primary;
  animation: pulse 2s infinite;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(239, 103, 71, 0.4);
  }
  100% {
    box-shadow: 0 0 0 25px rgba(239, 103, 71, 0);
  }
}

.chakra-radio__control[data-checked] {
  background-color: $primary !important;
  border-color: $primary !important;
}

.center-item {
  margin: 4rem 0;
  text-align: center;
  @media only screen and (max-width: $small) {
    margin: 2rem 0;
  }
}

@media only screen and (max-width: $medium) {
  .main-contianer .css-14xmlw6 {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
  }
  .main-contianer .css-x4kbrg {
    padding: 1rem 0;
  }
}

.css-isk0hu:focus,
.css-isk0hu[data-focus] {
  box-shadow: none !important;
}

input:focus {
  outline: $primary;
  border-color: $primary !important;
  box-shadow: none !important;
}

.fill-primary {
  fill: $primary;
}

.stroke-primary {
  stroke: $primary;
}

.fill-secondary {
  fill: $secondary;
}

// Temp here

.text-icon-conatiner {
  .icon-text {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & div {
      &:nth-child(1) {
        width: 30px;
        margin-right: 1rem;

        svg {
          color: $primary;
        }
      }

      &:nth-child(2) {
        width: calc(100% - 50px);
      }
    }
  }
}

.header-container {
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background: rgb(239,104,72);
  background: linear-gradient(90deg, rgba(239,104,72,1) 45%, rgba(232,62,36,1) 54%, rgba(243,142,100,1) 100%);
  color: white;

  @media only screen and (max-width: $small) {
    background: #ef6848;
  }
}

.video {
  margin-top: 2rem;
  width: 500px;
  height: 282px;
  position: relative;

  .overlay {
    position: absolute;
    top: 45%;
    height: 150px;
    width: 300px;
    left: 50%;
    z-index: 10;
    text-align: center;
    padding: 1rem;
    margin: -75px -150px 0;
    color: #ec695d;
    cursor: pointer;

    .text {
      background: white;
      padding: 0.5rem;
      border-radius: 5px;
      animation: pulse 2s infinite;
      transition: all 0.3s ease-in-out;

      h3 {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    svg {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }

  @media only screen and (max-width: $small) {
    width: 100%;
    height: 200px;
    margin: 1rem auto 0;
  }

  iframe {
    width: 100%;
    height: 100%;
    background: white;
  }
}
